@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600&display=swap);
html, body, #root, #root > div {
  height: 100%
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*:not(b) {
  font-family: Roboto, Arial, Helvetica, sans-serif !important;
  font-weight: 500 !important;
}

:root {
  --brand-blue: #0084D5;
  --brand-green: #78BE20;
  --brand-dark-blue: #006AC6;
  --brand-dark-green: #658D1B;
  --brand-light-blue: #40A8DB;
  --brand-light-green: #A1CC5D;
  --brand-lighter-blue: #80C5E7;
  --brand-lighter-green: #C0DD93;
  --brand-lightest-blue: #C0E2F3;
  --brand-lightest-green: #E0EEC9;
  --brand-red: #ee4566;
  --brand-dark-red: #d13c5a;
  --brand-light-red: #ee4365;
}

.App.container {
  padding: 0;
}

#main {
  background-color: #F1F1F2;
  display: flex;
  flex-direction: column;
}

.App {
  text-align: center;
  height: 100%;
  flex: 1 1;
  background-color: white;
}

.App.container {
  max-width: 500px;
}

.App .app-progress-bar-container {
  display: block;
  margin: auto;
  width: 80%;
}

.App .app-progress-bar {
  margin-top: 30px;
  color: #ee4566;
  color: var(--brand-red);
  border: 2px solid #ee4566;
  border: 2px solid var(--brand-red);
  background-color: white;
  border-radius: 12px;
}

.App .app-progress-bar .progress-bar {
  background-color: #ee4566;
  background-color: var(--brand-red);
}

.navbar-brand {
  width: 100%;
  text-align: center;
}

.large-main-text {
  font-size: 1.35em;
  margin: 15px 0;
  text-align: left;
}

.first-screen {
  height: 100%;
  padding-top: min(120px, 10vh);
  padding-left: min(30px, 10vw);
  padding-right: min(30px, 10vw);
}

.first-screen>div {
  font-size: 1.35em;
  margin-bottom: min(120px, 10vh);
}

.first-screen .btn-primary {
  background-color: #ee4566;
  background-color: var(--brand-red);
  border-radius: 24px;
  padding: 8px 30px;
  font-size: 1.2em;
  border-color: #ee4566;
  border-color: var(--brand-red);
}

.first-screen .btn-primary:hover, .first-screen .btn-primary:not(:disabled):not(.disabled):active{
  background-color: #d13c5a;
  background-color: var(--brand-dark-red);
  border-color: #ee4566;
  border-color: var(--brand-red);
}

.first-screen .btn-primary.focus,.first-screen .btn-primary:focus,.btn-primary:not(:disabled):not(.disabled):active{
  box-shadow: 0 0 0 0.2rem rgb(238 67 101 / 50%) !important;
}

.second-screen {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.App .white-next-button {
  margin-top: min(50px, 4vh);
  background-color: #ee4566;
  background-color: var(--brand-red);
  color: aliceblue;
  border: 1px solid #ee4566;
  border: 1px solid var(--brand-red);
  border-radius: 24px;
  padding: 8px 30px;
  font-size: 1.2em;
  font-weight: 600;
}

.App .submit-button {
  margin-top: 0;
}

.App .white-next-button:focus {
  background-color: #ee4566;
  background-color: var(--brand-red);
  color: aliceblue;
  stroke: white;
}

.App .white-next-button:disabled {
  background-color: lightgrey;
  color: darkgray;
  border-color: lightgrey;
}

.App .white-next-button:hover {
  background-color: #ee4566 !important;
  background-color: var(--brand-red) !important;
  color: aliceblue !important;
  stroke: white;
}

.App .white-next-button:active {
  background-color: #ee4566 !important;
  background-color: var(--brand-red) !important;
  color: aliceblue !important;
}

.App .green-next-button, .App .green-next-button:hover, .App .green-next-button:active {
  background-color: #78BE20 !important;
  background-color: var(--brand-green) !important;
  color: aliceblue !important;
  border-color: #78BE20;
  border-color: var(--brand-green);
}

.App .green-next-button svg, .App .red-next-button svg {
  transform: scale(0.8, 0.8);
}

.App .green-next-button svg path:first-child, .App .red-next-button svg path:first-child {
  transform: translateX(-2px);
}

.App .green-next-button svg path {
  stroke: aliceblue;
}

.App .btn-primary:focus, .App .btn-primary:active{
  border-color: #fff;
  box-shadow: 0 0 0 0.2rem rgb(255 255 255 / 50%);
  color:white !important;
}

.App .red-next-button{
  background-color:white;
  color: #ee4566 !important;
  color: var(--brand-red) !important;
  stroke: #ee4566;
  stroke: var(--brand-red);
  stroke-width:4px;
  border-color: white !important;
}

.App .red-next-button:hover,.App .red-next-button:focus,.App .red-next-button:click{
  stroke: white;
}




.description-box {
  color: white;
  padding: 10px;
  margin-top: 25px;
  flex: 0.7 1;
  border-radius: 50%/30px 30px 0 0;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  transition: all .4s ease-in-out;
  transform-origin: bottom;
  transform: scaleY(0);
  justify-content: center;
}

.large-main-comment {
  transition: all .6s ease-in-out;
  transform-origin: bottom;
  height: 0;
}

.large-main-comment.show {
  height: auto;
}

.description-box.show {
  background-color: #ee4566;
  background-color: var(--brand-red);
  transform: scaleY(1);
  min-height: 50vh;
}

.white-next-button {
  position: absolute;
  right: 40px;
  bottom: 3vh;
}



.label-text {
  font-size: 1.2em;
}

.label-text.left {
  text-align: left;
}

.label-text.right {
  text-align: right;
}

.description-box>.large-main-text {
  width: 90%;
  display: block;
  margin: 15px auto;
}

.align-icons {
  margin-bottom: 4px;
}

.input-box {
  width: 80%;
  display: block;
  margin: auto;
}

.App .slider {
  margin-top: 25px;
}

.slider-hide::-webkit-slider-thumb {
  opacity: 0;

}

input[type=range] {
  background-color: black;
  background-image: url(https://ej2.syncfusion.com/react/demos/src/slider/images/thumb.png);
}

.slider-hide::-moz-range-thumb {
  opacity: 0;
}

.slider-visible::-webkit-slider-thumb {
  opacity: 1;
}

.slider-visible::-moz-range-thumb {
  opacity: 1;
}

.range-slider__tooltip, .range-slider__tooltip--auto {
  opacity: 0 !important;
}

.App input[type='range']::-webkit-slider-thumb {
  background-color: #ee4566 !important;
  background-color: var(--brand-red) !important;
}
.App input[type='range']::-webkit-slider-thumb:active {
  box-shadow: 0 0 0 0.2rem rgb(238 67 101 / 50%) !important;
}

.App .btn-primary:hover{
  border-color: #fff;
  background-color: #d13c5a;
  background-color: var(--brand-dark-red);
}
.left {
  text-align: left;
}

/* #last-screen {
  height: 100%;
  padding-top: min(60px, 5vh);
  padding-left: min(40px, 10vw);
  padding-right: min(40px, 10vw);
} */

#thank-you-label {
  position: relative;
  padding-top: min(45px, 5vh);
  padding-bottom: min(45px, 5vh);
  height: 18%;
  background-color: #ee4566;
  background-color: var(--brand-red);
  border-radius: 50%/0 0 30px 30px;
  color: white;
}

#thank-you-label div {
  text-align: center;
}

/* #thank-you-label::before{
  content:'';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  border-radius: 0 0 50% 50%;
  background-color: var(--brand-blue);
} */

#thank-you-text {
  font-size: 1.35em;
  padding-left: min(40px, 10vw);
  padding-right: min(40px, 10vw);
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.navbar.navbar-light {
  padding: 0;
}

.navbar.navbar-light .navbar-brand {
  padding: 0;
}

.banner-logo {
  height: 50px;
  width: 100%;
  object-fit: contain;
  padding: 5px;
}

input.custom-range-sider[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px !important;
  cursor: pointer;
  background: #fff !important;
  border-radius: 50px !important;
  border: 3px solid #ee4566 !important;
  border: 3px solid var(--brand-red) !important;
}

input.custom-range-sider[type=range]::-webkit-slider-thumb {
  height: 25px !important;
  width: 25px !important;
  -webkit-appearance: none;
  margin-top: -10px !important;
}
